var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white w-96 px-6 py-4 rounded-md overflow-x-hidden break-all"},[_c('div',{staticClass:"flex mb-1"},[_c('svg',{staticClass:"w-6 h-6 mr-3 text-dark-gray",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"}})]),_c('svg',{staticClass:"w-6 h-6 mr-3 text-dark-gray",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"}})]),_c('svg',{staticClass:"w-6 h-6 text-dark-gray",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"}})])]),_vm._l((_vm.words),function(word,i){return _c('div',{key:word[0],staticClass:"flex mb-4"},[_c('div',{staticClass:"flex mr-5 w-24"},[_c('div',{staticClass:"relative"},[_c('input',{staticClass:"absolute opacity-0",attrs:{"name":("word-" + (word[0])),"id":("show-" + i),"type":"radio","value":"show"},on:{"change":function($event){return _vm.onChange($event, word[0])}}}),_c('label',{class:("block w-6 h-6 rounded-sm mr-3 border\n            " + (!_vm.disabledWords.includes(word[0]) &&
              !_vm.pausedWords.includes(word[0])
                ? 'border-green-700 bg-secondary-light'
                : 'border-dark-gray') + "\n          "),attrs:{"for":("show-" + i)}})]),_c('div',{staticClass:"relative"},[_c('input',{staticClass:"absolute opacity-0",attrs:{"name":("word-" + (word[0])),"id":("keep-" + i),"type":"radio","value":"keep"},on:{"change":function($event){return _vm.onChange($event, word[0], word[1])}}}),_c('label',{class:("block w-6 h-6 rounded-sm mr-3 border\n            " + (_vm.pausedWords.includes(word[0])
                ? 'border-yellow-700 bg-warning-light'
                : 'border border-dark-gray') + "\n          "),attrs:{"for":("keep-" + i)}})]),_c('div',{staticClass:"relative"},[_c('input',{staticClass:"absolute opacity-0",attrs:{"name":("word-" + (word[0])),"id":("remove-" + i),"type":"radio","value":"remove"},on:{"change":function($event){return _vm.onChange($event, word[0])}}}),_c('label',{class:("block w-6 h-6 rounded-sm mr-3 border\n            " + (_vm.disabledWords.includes(word[0]) &&
              !_vm.pausedWords.includes(word[0])
                ? 'border-red-700 bg-destructive-light'
                : 'border border-dark-gray') + "\n          "),attrs:{"for":("remove-" + i)}})])]),_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(word[1])+" ")]),_c('p',{staticClass:"font-bold text-lg"},[_vm._v(" "+_vm._s(word[0])+" ")])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }