var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-center"},[_c('input',{staticClass:"\n        block\n        py-3\n        px-6\n        pr-14\n        w-1/2\n        text-2xl\n        font-medium\n        leading-6\n        text-dark-gray\n        bg-white\n        shadow-reaction\n        focus:ring-primary focus:border-primary\n        border-none\n        rounded-card\n        placeholder-light-gray\n      ",attrs:{"type":"text","placeholder":this.placeholder},domProps:{"value":_vm.inputValue(this.labels, this.currentSession.labels, this.placeholder)},on:{"input":function (e) { return _vm.setLabel({
            labelId: _vm.slug,
            label: e.target.value,
          }); }}}),(
        _vm.currentSession.labels
          ? _vm.labels[_vm.slug] && _vm.currentSession.labels[_vm.slug] != _vm.labels[_vm.slug]
          : _vm.labels[_vm.slug] && _vm.labels[_vm.slug] != _vm.placeholder
      )?_c('SaveButton',{attrs:{"onClick":_vm.saveLabel,"className":"-ml-10 mr-8"}}):_c('div',{staticClass:"-ml-10 mr-8 w-5 h-5 text-primary"},[_c('svg',{attrs:{"className":"w-5 h-5","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"}})])]),(!_vm.hideTVButton)?_c('TVButton',{attrs:{"tvMode":_vm.slug,"currentTVMode":_vm.currentSession.tv}}):_vm._e()],1),(_vm.showDescriptionField)?_c('div',{staticClass:"w-1/2 flex flex-col items-end space-y-2 mt-5"},[_c('textarea',{staticClass:"\n        block\n        w-full\n        py-6\n        px-5\n        text-dark-gray\n        focus:ring-primary focus:border-primary\n        rounded-card\n        border-none\n        shadow-reaction\n        placeholder-light-gray\n      ",attrs:{"id":"description","name":"description","rows":"2","placeholder":_vm.$t('components.presenter.pageHeadingPlaceholderDescription')},domProps:{"value":_vm.descriptions[_vm.slug] ||
        (_vm.currentSession.descriptions && _vm.currentSession.descriptions[_vm.slug])},on:{"input":function (e) { return _vm.setDescription({
            descriptionId: _vm.slug,
            description: e.target.value,
          }); }}}),(
        _vm.currentSession && _vm.currentSession.descriptions
          ? _vm.descriptions[_vm.slug] &&
            _vm.currentSession.descriptions[_vm.slug] != _vm.descriptions[_vm.slug]
          : _vm.descriptions[_vm.slug]
      )?_c('SaveButton',{attrs:{"onClick":_vm.saveDescription}}):_vm._e()],1):_vm._e(),(_vm.showButtonField)?_c('div',{staticClass:"flex items-center mt-5"},[_c('input',{staticClass:"\n        block\n        py-3\n        px-6\n        pr-14\n        w-1/2\n        text-md\n        font-medium\n        leading-6\n        text-dark-gray\n        bg-white\n        shadow-reaction\n        focus:ring-primary focus:border-primary\n        border-none\n        rounded-card\n        placeholder-light-gray\n      ",attrs:{"type":"text","placeholder":"Buttontext"},domProps:{"value":_vm.inputValue(
          this.buttonLabels,
          this.currentSession.buttonLabels,
          this.buttonPlaceholder
        )},on:{"input":function (e) { return _vm.setButtonLabel({
            buttonId: _vm.slug,
            buttonLabel: e.target.value,
          }); }}}),(
        _vm.currentSession.buttonLabels
          ? _vm.buttonLabels[_vm.slug] &&
            _vm.currentSession.buttonLabels[_vm.slug] != _vm.buttonLabels[_vm.slug]
          : _vm.buttonLabels[_vm.slug] && _vm.buttonLabels[_vm.slug] != _vm.buttonPlaceholder
      )?_c('SaveButton',{attrs:{"onClick":_vm.saveButtonLabel,"className":"-ml-10 mr-8"}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }