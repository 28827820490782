




























































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PageHeading from '@/components/Presenter/PageHeading.vue';
import WordEditor from '@/components/Presenter/WordEditor.vue';

export default Vue.extend({
  components: { PageHeading, WordEditor },
  data() {
    return {
      removeList: [] as any,
      updated: false,
      tempTopic: '',
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      wordclouds: 'presenter/getSessionWordclouds',
    }),

    wordcloud(): any {
      if (this.wordclouds && this.currentSession) {
        if ('activeWordcloud' in this.currentSession) {
          const [wordcloud] = this.wordclouds.filter(
            (item: any) => item.id === this.currentSession.activeWordcloud
          );

          return wordcloud;
        }
      }
      return {};
    },

    words(): any[] {
      if (this.wordcloud) {
        const list: any[] = [];
        for (const key in this.wordcloud) {
          if (key !== 'id') {
            list.push([key, this.wordcloud[key]]);
          }
        }

        return list;
      }
      return [];
    },

    topic: {
      get(): string {
        if (this.tempTopic !== '') return this.tempTopic;
        if (this.currentSession) {
          if ('wordcloudTopic' in this.currentSession) {
            return this.currentSession.wordcloudTopic;
          }
        }
        return '';
      },
      set(value: string): any {
        this.tempTopic = value;
      },
    },

    activeWords() {
      let activeWordList: any[] = this.words;

      if (this.currentSession && this.currentSession.deactivatedWordsList) {
        activeWordList = activeWordList.filter(
          (n) => !this.currentSession.deactivatedWordsList.includes(n[0])
        );
      }

      return activeWordList;
    },

    disabledWords(): string[] {
      return this.currentSession.deactivatedWordsList ?? [];
    },

    pausedWords(): string[] {
      const keys: any = [];

      if (
        this.currentSession.pausedWordsList &&
        this.currentSession.pausedWordsList.length > 0
      ) {
        this.currentSession.pausedWordsList.map((obj: any) => {
          const objectArray = Object.entries(obj);
          objectArray.forEach(([key]) => {
            keys.push(key);
          });
        });
      }

      return keys;
    },
  },
  methods: {
    onChange(event: any, word: string, upVotes: number) {
      const activeWordList: any[] = this.words;
      let pausedWordsList: any[] = this.currentSession.pausedWordsList || [];
      let deactivatedWordsList: string[] =
        this.currentSession.deactivatedWordsList || [];

      if (event.target.value === 'show') {
        // remove word from deactivatedWordList and the pausedWordList
        deactivatedWordsList = deactivatedWordsList.filter(
          (deactivatedWord) => deactivatedWord !== word
        );

        pausedWordsList = pausedWordsList.filter(
          (pausedWord) => !Object.keys(pausedWord).includes(word)
        );

        this.$store.dispatch('presenter/updatePausedWordList', {
          pausedWordsList,
        });

        this.$store.dispatch('presenter/updateDeactivatedWords', {
          deactivatedWordsList,
        });
      } else if (event.target.value === 'keep') {
        // adding word to pausedWordList and removing it from the deactivatedWordList
        pausedWordsList.push({ [word]: upVotes });

        deactivatedWordsList = deactivatedWordsList.filter(
          (deactivatedWord) => deactivatedWord !== word
        );

        this.$store.dispatch('presenter/updateDeactivatedWords', {
          deactivatedWordsList,
        });

        this.$store.dispatch('presenter/updatePausedWordList', {
          pausedWordsList,
        });
      } else if (event.target.value === 'remove') {
        // adding word to deactivatedWordList and removing it from the pausedWordList
        activeWordList.map((n) => {
          if (n.includes(word)) {
            deactivatedWordsList.push(n[0]);
          }
        });

        pausedWordsList = pausedWordsList.filter(
          (pausedWord) => !Object.keys(pausedWord).includes(word)
        );

        this.$store.dispatch('presenter/updatePausedWordList', {
          pausedWordsList,
        });

        this.$store.dispatch('presenter/updateDeactivatedWords', {
          deactivatedWordsList,
        });
      }
    },

    toggleWordcloudVisibility(state: boolean) {
      this.$store.dispatch('presenter/toggleWordcloudVisibility', {
        state,
      });
    },
    resetWordcloud() {
      if (
        confirm(this.$t('views.presenter.session.wordcloudsResetMsg') as string)
      ) {
        this.$store.dispatch('presenter/resetWordcloud');
      }
    },
    pauseWordcloud() {
      this.$store.dispatch('presenter/toggleWordcloudPaused', {
        state: true,
      });
    },
    continueWordcloud() {
      this.$store.dispatch('presenter/toggleWordcloudPaused', {
        state: false,
      });
    },
    submitTopicUpdate() {
      this.$store.dispatch('presenter/updateWordcloudTopic', {
        topic: this.tempTopic,
      });
    },
  },
});
