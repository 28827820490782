











import Vue from 'vue';
export default Vue.extend({
  props: ['tvMode', 'currentTVMode'],
  methods: {
    activateTVMode() {
      this.$store.dispatch('presenter/activateTVMode', {
        tvMode: this.tvMode,
        state: this.currentTVMode !== this.tvMode,
      });
    },
  },
});
