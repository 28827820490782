











































































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    words: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    disabledWords: {
      type: Array,
      required: true,
    },
    pausedWords: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
});
